import { Link, useLocation, useNavigate } from '@remix-run/react';
import { type ReactNode } from 'react';

import { useGamePackContext } from '../../pages/GamePack/Context';
import { ConfirmCancelModalRoot } from '../ConfirmCancelModalContext';
import { CollapsibleSearchBar, CommonNav } from '../Header';
import { HeaderAvatar } from '../HeaderAvatar';
import { LunaParkLogo } from '../icons/LogoIcon';
import { NotificationCenter } from '../Notification';
import { useIsMyOrganizationFree } from '../Organization';
import { OrgSubscriptionUpgradeButton } from '../Organization/OrgSubscription';
import { DefaultPageProvider } from '../PageProvider';

function SearchBar(): JSX.Element {
  const ctx = useGamePackContext();
  const location = useLocation();
  const navigate = useNavigate();

  const handleSearchChanged = (q: string) => {
    const trimmed = q.trim();

    if (!ctx.embed) {
      const params = new URLSearchParams(location.search);
      if (trimmed) {
        params.set('q', trimmed);
        navigate({
          pathname: '/search',
          search: params.toString(),
        });
      } else {
        params.delete('q');
      }
      return;
    }
    if (trimmed) {
      const params = new URLSearchParams(ctx.embedCtx.search);
      params.set('q', trimmed);
      ctx.updateEmbedCtx({ search: params.toString() });
    } else {
      ctx.updateEmbedCtx({ search: undefined });
    }
  };

  return <CollapsibleSearchBar onChange={handleSearchChanged} />;
}

export function PublicHomeHeader(props: { withSearch?: boolean }): JSX.Element {
  const isFree = useIsMyOrganizationFree();

  return (
    <div className='w-full flex-none h-15 bg-black border-secondary border-b flex items-center z-40 pl-10'>
      <div className='h-full flex-none flex items-center gap-7.5'>
        <div className='flex-none'>
          <Link to='/home'>
            <LunaParkLogo className='w-27 h-7.5' />
          </Link>
        </div>

        {isFree && <OrgSubscriptionUpgradeButton />}
      </div>
      <div className='h-full flex-1 flex items-center justify-end gap-7.5 xl:gap-10'>
        {props.withSearch && <SearchBar />}
        <CommonNav />
        <div className='mr-3'>
          <HeaderAvatar />
        </div>
      </div>
    </div>
  );
}

export function PublicHomeLayout({
  customHeader,
  children,
}: {
  customHeader?: boolean;
  children?: ReactNode;
  fullHeight?: boolean;
}): JSX.Element {
  return (
    <DefaultPageProvider>
      <div className='h-full w-full bg-black flex flex-col'>
        {!customHeader && <PublicHomeHeader />}
        {children}
      </div>
      <NotificationCenter
        className='!w-108'
        position='bottom-right'
        toastClassName='rounded my-2'
      />
      <ConfirmCancelModalRoot />
    </DefaultPageProvider>
  );
}
